<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ salesDelivery.code }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ salesDelivery.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("SALES_DELIVERIES.EXPIRATION_TIME") }}
          </dt>
          <dd>
            {{ salesDelivery.expiration_time | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="salesDelivery.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="salesDelivery.organization" />
          </dd>
        </dl>

        <dl class="row" v-if="salesDelivery.salesOrder">
          <dt class="col-sm-4">{{ $t("COMMON.SALES_ORDER") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="salesDelivery.salesOrder" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.RECIPIENT") }}</dt>
          <dd>
            <object-link :object="salesDelivery.recipient" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <sales-delivery-status-badge :salesDelivery="salesDelivery" />
          </dd>
        </dl>

        <dl class="row" v-if="salesDelivery.excerpt">
          <dt>
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd>
            <div v-html="salesDelivery.excerpt"></div>
          </dd>
        </dl>

        <div class="col-12 text-center mb-5 mt-5">
          <h2>{{ $t("COMMON.DELIVERY_ADDRESS") }}</h2>
        </div>

        <dl
          class="row"
          v-if="
            salesDelivery.delivery_entity_type ===
            BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt>{{ $t("COMMON.LASTNAME") }}</dt>
          <dd>
            {{ salesDelivery.delivery_lastname }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="
            salesDelivery.delivery_entity_type ===
            BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd>
            {{ salesDelivery.delivery_firstname }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="
            salesDelivery.delivery_entity_type === BILLING_ENTITY_TYPE_COMPANY
          "
        >
          <dt>{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd>
            {{ salesDelivery.delivery_company_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.COUNTRY") }}</dt>
          <dd>
            {{ salesDelivery.delivery_country }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATE") }}</dt>
          <dd>
            {{ salesDelivery.delivery_state }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CITY") }}</dt>
          <dd>
            {{ salesDelivery.delivery_city }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd>
            {{ salesDelivery.delivery_zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ salesDelivery.delivery_address }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ salesDelivery.delivery_email }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import SalesDeliveryStatusBadge from "./SalesDeliveryStatusBadge.vue";

export default {
  name: "sales-delivery-view-global",

  components: {
    SalesDeliveryStatusBadge,
  },

  props: ["salesDelivery"],

  data() {
    return {
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    salesDelivery(salesDelivery) {},
  },
};
</script>
