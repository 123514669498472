var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elite-tabs-wrapper-content"},[_c('h3',[_vm._v(_vm._s(_vm.salesDelivery.code))]),_c('div',{staticClass:"all-infos"},[_c('div',{staticClass:"all-infos-text"},[_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.CREATED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.salesDelivery.created_at,"LLLL"))+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("SALES_DELIVERIES.EXPIRATION_TIME"))+" ")]),_c('dd',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.salesDelivery.expiration_time,"LLLL"))+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.LOCATIONS")))]),_c('dd',[_c('locations',{attrs:{"locations":_vm.salesDelivery.allowedLocations}})],1)]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.ORGANIZATION")))]),_c('dd',[_c('organization',{attrs:{"organization":_vm.salesDelivery.organization}})],1)]),(_vm.salesDelivery.salesOrder)?_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-4"},[_vm._v(_vm._s(_vm.$t("COMMON.SALES_ORDER")))]),_c('dd',{staticClass:"col-sm-8"},[_c('object-link',{attrs:{"object":_vm.salesDelivery.salesOrder}})],1)]):_vm._e(),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.RECIPIENT")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.salesDelivery.recipient}})],1)]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.STATUS")))]),_c('dd',[_c('sales-delivery-status-badge',{attrs:{"salesDelivery":_vm.salesDelivery}})],1)]),(_vm.salesDelivery.excerpt)?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("COMMON.NOTE"))+" ")]),_c('dd',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.salesDelivery.excerpt)}})])]):_vm._e(),_c('div',{staticClass:"col-12 text-center mb-5 mt-5"},[_c('h2',[_vm._v(_vm._s(_vm.$t("COMMON.DELIVERY_ADDRESS")))])]),(
          _vm.salesDelivery.delivery_entity_type ===
          _vm.BILLING_ENTITY_TYPE_INDIVIDUAL
        )?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.LASTNAME")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesDelivery.delivery_lastname)+" ")])]):_vm._e(),(
          _vm.salesDelivery.delivery_entity_type ===
          _vm.BILLING_ENTITY_TYPE_INDIVIDUAL
        )?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.FIRSTNAME")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesDelivery.delivery_firstname)+" ")])]):_vm._e(),(
          _vm.salesDelivery.delivery_entity_type === _vm.BILLING_ENTITY_TYPE_COMPANY
        )?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.COMPANY_NAME")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesDelivery.delivery_company_name)+" ")])]):_vm._e(),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.COUNTRY")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesDelivery.delivery_country)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.STATE")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesDelivery.delivery_state)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.CITY")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesDelivery.delivery_city)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.ZIPCODE")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesDelivery.delivery_zipcode)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.ADDRESS")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesDelivery.delivery_address)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.EMAIL")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesDelivery.delivery_email)+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }